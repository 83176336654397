import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { getConfiguration } from '~/utils/configurations';

const Auth0ProviderWithHistory: React.FC = ({ children }) => {
  const domain = getConfiguration('REACT_APP_AUTH0_DOMAIN');
  const clientId = getConfiguration('REACT_APP_AUTH0_CLIENT_ID');

  const history = useHistory();

  const onRedirectCallback = (appState: AppState | undefined) => {
    if (appState?.returnTo && appState?.returnToSearch) {
      const route = `${appState?.returnTo ?? ''}${
        appState?.returnToSearch ?? ''
      }`;
      history.push(route);
    } else {
      history.push(appState?.returnTo || window.location.pathname);
    }
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={getConfiguration('REACT_APP_AUTH0_AUDIENCE')}
      scopes={getConfiguration('REACT_APP_AUTH0_SCOPES')}
      scope="offline_access"
      cacheLocation="localstorage"
      useRefreshTokens
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
