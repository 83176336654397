export const ANZA_ENGINE_STATES = {
  ACTIVE: 'active',
  COMPLETE: 'complete',
  error: 'error',
} as const;

export const ANZA_ENGINE_STEP_STATES = {
  COMPLETE: 'complete',
  ERROR: 'error',
  GENESIS: 'genesis',
  REQUESTED: 'requested',
} as const;
