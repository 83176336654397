import React from 'react';
import { useParams } from 'react-router';

import { Drawer, Form } from '~/UI';
import {
  ProjectDetails,
  StorageAdvancedInputs,
  StorageProject,
  UpdateProjectArgs,
} from '~/store/project';
import { TEST_DATA_COMPONENTS, generateTestId } from '~/utils/dataTestProps';
import { omitProjectDetailsValues, pickProjectDetailsValues } from '~/utils';
import { useUpdateStorageProjectMutation } from '~/store/api';
import confirmModal from '~/utils/confirmModal';

import ProjectDrawerFooter from '../shared/ProjectDrawerFooter';
import StorageProjectDrawerForm from './StorageProjectDrawerForm';
import { transformAdvancedInputs } from '../shared/helpers';

interface Props {
  projectData: StorageProject;
  onCloseDrawer: (isUpdate?: boolean) => void;
  isOpen: boolean;
  advancedInputs: StorageAdvancedInputs;
  enableAdvanceInputs: boolean;
}

const StorageProjectDrawer = ({
  projectData,
  onCloseDrawer,
  isOpen,
  advancedInputs,
  enableAdvanceInputs,
}: Props) => {
  const params = useParams<{ uuid: string }>();
  const [projectDetailsForm] = Form.useForm();
  const [updateStorageProject, { isLoading }] =
    useUpdateStorageProjectMutation();

  const handleOnCloseDrawer = () => {
    const fieldsToCheck = Object.keys(
      projectDetailsForm.getFieldsValue() || {}
    );
    const formIsDirty = projectDetailsForm.isFieldsTouched(fieldsToCheck);
    if (formIsDirty) {
      confirmModal({
        title: 'You have unsaved changes in your project details.',
        onOk: () => {
          projectDetailsForm.resetFields();
          onCloseDrawer();
        },
      });
    } else {
      projectDetailsForm.resetFields();
      onCloseDrawer();
    }
  };

  const handleOnSaveDrawer = () => {
    projectDetailsForm.submit();
    projectDetailsForm.validateFields().then((values) => {
      const { is_larger_purchase, ...payload } = transformAdvancedInputs(
        values,
        advancedInputs
      );

      updateStorageProject({
        uuid: params.uuid,
        project_details: {
          ...pickProjectDetailsValues(payload),
          project_type: projectData.project_details.project_type,
          project_address: projectData.project_details.project_address,
        } as unknown as Partial<ProjectDetails>,
        ...omitProjectDetailsValues(payload),
        advancedInputs: payload.advancedInputs && {
          ...advancedInputs,
          ...payload.advancedInputs,
        },
      } as unknown as UpdateProjectArgs)
        .unwrap()
        .then(() => {
          onCloseDrawer(true);
        });
    });
  };

  return (
    <Drawer
      width={540}
      open={isOpen}
      onClose={handleOnCloseDrawer}
      title={
        <h2
          data-testid={generateTestId({
            component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.TEXT}`,
            identifier: 'title',
          })}
        >
          Project Details
        </h2>
      }
      footer={
        <ProjectDrawerFooter
          isLoading={isLoading}
          onCloseDrawer={handleOnCloseDrawer}
          onSaveDrawer={handleOnSaveDrawer}
        />
      }
    >
      <p
        data-testid={generateTestId({
          component: `${TEST_DATA_COMPONENTS.EDIT_PROJECT_DRAWER}-${TEST_DATA_COMPONENTS.TEXT}`,
          identifier: 'info',
        })}
      >
        Modify the assumptions about your project to improve your ranking
        results.
      </p>
      <StorageProjectDrawerForm
        data={projectData}
        form={projectDetailsForm}
        disabledInputs={false}
        defaultAdvancedInputs={advancedInputs}
        disabledAdvancedInputs={!enableAdvanceInputs}
      />
    </Drawer>
  );
};

export default StorageProjectDrawer;
